<ng-container *transloco="let t; read: translateScope">
  <p-messages
    *ngIf="!data.length"
    [value]="[{  severity: bannerSeverity, summary: '', key: 'animal-selector', detail: t('banner-message')}]"
    [closable]="false"
    [key]="'animal-selector'"
    styleClass="banner-sm banner-required-msg"
  />
  <ifhms-cattle-selector-modal [save]="setData" [woAttributes]="woAttributes"></ifhms-cattle-selector-modal>
  <ifhms-cattle-selector-data-table
    [data]="data"
    [disabled]="disabled"
    [showDialog]="showDialog"
  ></ifhms-cattle-selector-data-table>

  <div class="bottom-section">
    <p>
      @if (data.length === 1) {
        <b>{{ t('animal-total') + ' ' + data.length.toString() + ' ' + t('animal')}}</b>
      } @else {
        <b>{{ t('animal-total') + ' ' + data.length.toString() + ' ' + t('animals')}}</b>
      }
    </p>

    <button
      *ngIf="!disabled && data.length > 0"
      (click)="onEdit()"
      [attr.data-ele]="'edit-btn'"
      [label]="t('edit')"
      icon="pi pi-pencil"
      class="btn-styles flex-grow-1 apply mt-2 mr-0"
      [style]="{ float: 'right' }"
      pButton
      type="submit"
    ></button>
  </div>
</ng-container>
