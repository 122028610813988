<ng-container *transloco="let t; read: translateScope">
  <button
    pButton
    #search
    icon="pi pi-search"
    class="search"
    iconPos="left"
    data-ele="table-search-btn"
    [label]="t('search')"
    (click)="menu.toggle($event)"
  ></button>
  <p-overlayPanel #menu>
    <ng-template pTemplate>
      <form
        sersiClickStopPropagation
        class="overlay-form"
        data-ele="search-form"
        [formGroup]="form"
      >
        <formly-form
          [fields]="fields"
          [form]="form"
          [model]="model"
        />
      </form>
      <div class="flex gap-1 cattle-selector">
        <button
          pButton
          type="submit"
          class="flex-grow-1 apply"
          data-ele="apply-btn"
          [label]="t('apply')"
          [disabled]="form.pristine || !form.valid"
          (click)="onApply()"
        ></button>
        <button
          pButton
          class="flex-grow-1 search mr-0"
          data-ele="clear-btn"
          [label]="t('clear')"
          [disabled]="!((facade.filterApplied$ | async) || !form.pristine)"
          (click)="clear()"
        ></button></div
    ></ng-template>
  </p-overlayPanel>
</ng-container>
