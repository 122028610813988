<p-dialog
  *transloco="let t; read: translateScope"
  [header]="t('heading')"
  [(visible)]="display"
  [modal]="true"
  [closable]="true"
  [closeOnEscape]="false"
  [closeOnEscape]="false"
  (onHide)="onCancel()"
  [style]="{ width: '90vw', height: '90vh' }"
  [draggable]="false"
  [maximizable]="true"
  appendTo="body"
>
  <sersi-spin [spin]="loading$ | async">
    <div class="flex flex-column gap-3">
      <div class="flex justify-content-between mb-2">
        <sersi-search
          (search)="onSearchTextChanged($event)"
          [value]="sortGroupListFacade.search$ | async"
        ></sersi-search>
        {{sortGroupListFacade.search$ | async}}
        <ifhms-sort-group-search-table-filter></ifhms-sort-group-search-table-filter>
      </div>
      <ifhms-sort-group-search-table class="w-full" [data]="table | async">
      </ifhms-sort-group-search-table>
    </div>
  </sersi-spin>

  <ng-template pTemplate="footer">
    <div class="flex gap-1 justify-content-end mt-3">
      <button
        pButton
        [attr.data-ele]="'cancel-btn'"
        class="p-button-secondary p-button-text"
        [label]="t('cancel')"
        (click)="onCancel()"
        [disabled]="loading$ | async"
        [rounded]="true"
        [text]="true"
      ></button>

      <button
        pButton
        [attr.data-ele]="'select-btn'"
        class="ml-3"
        (click)="onSubmit()"
        [disabled]="!formlyForm?.selectedId || (loading$ | async)"
        [label]="t('select')"
        [icon]="'pi pi-check'"
        [rounded]="true"
      ></button>
    </div>
  </ng-template>
</p-dialog>
