<ng-container *transloco="let t; read: scopedNamespace">
    <formly-form
            [fields]="fields"
            [form]="form"
            [model]="model"
    >
    </formly-form>
    <br/>
    <button
            pButton
            type="submit"
            [disabled]="form.pristine"
            (click)="onUpdateDosingGuns()"
            class="p-button-raised"
    > {{ t('save-button') }}
    </button>
</ng-container>