import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NationalIdValidationCode } from '@ifhms/models/feedlot';
import { Observable } from 'rxjs';
import { AH_FMS_FEEDLOT_API_CONFIG, AhFmsFeedlotApiConfig } from '../../tokens';

@Injectable()
export class NationalIdService {

  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_FEEDLOT_API_CONFIG) private apiConfig: AhFmsFeedlotApiConfig
  ) {}

  getAnimalTagValidationState(feedlotId: string, nationalId: number): Observable<NationalIdValidationCode> {
    const params = new HttpParams()
      .set('nationalId', nationalId ?? 0);

    return this.http.get<NationalIdValidationCode>(`${this.apiConfig.apiUrl}/Feedlot/${feedlotId}/Animals/NationalIdValidation`, { params });
  }
}
