<sersi-spin [spin]="sortGroupDetailFacade.isLoading$ | async">
  <ng-container *ngIf="groupDetail | async as detail">
    <ng-container *transloco="let t; read: translateScope">
      <div class="p-field">
        <h4 class="sersi-label">
          {{ t('details') }}
          <span *ngIf="required" class="required-indicator" aria-hidden="true">*</span>
        </h4>
      </div>
      <ng-template #elseBlock>
        <button
          pButton
          type="button"
          [label]="detail?.sortGroupName || ''"
          [attr.data-ele]="'edit-template-btn'"
          (click)="showOucomesModal()"
          icon="pi pi-pencil"
          style="min-width: 12rem"
          iconPos="right"
          class="p-button-outlined p-button-primary"
        ></button>

        <button
          pButton
          type="button"
          [label]="'change'"
          [attr.data-ele]="'change-template-btn'"
          (click)="showSearchModal()"
          icon="pi pi-arrow-right-arrow-left"
          class="p-button-outlined p-button-primary ml-5"
        ></button>
      </ng-template>

      <button
        pButton
        *ngIf="!detail?.sortGroupId; else elseBlock"
        type="button"
        [label]="t('manage')"
        [attr.data-ele]="'manage-template-btn'"
        (click)="showSearchModal()"
        icon="pi pi-cog"
        class="p-button-outlined p-button-primary custom-ctrl-element"
      ></button>

      <ifhms-sort-group-outcome-modal
        [onModelClosed]="onModelClosed"
        (viewClick)="onViewClick($event)"
        (createOutput)="onCreateOutput($event)"
      ></ifhms-sort-group-outcome-modal>
      <ifhms-sort-group-outcome-grid-modal
        (viewClick)="onViewClick($event)"
      ></ifhms-sort-group-outcome-grid-modal>
      <ifhms-sort-group-search-modal></ifhms-sort-group-search-modal>
      <ifhms-sort-group-form-modal
        [hasTagsInputs]="hasTagsInputs"
        [selectedOutcomes]="
          (sortGroupOutcomeListFacade.selected$ | async) || []
        "
      ></ifhms-sort-group-form-modal>
      <ifhms-sort-group-outcome-view-modal [hasTagsInputs]="hasTagsInputs"/>
    </ng-container>
  </ng-container>
  <p-confirmDialog
    [baseZIndex]="10000"
    [style]="{ width: '30rem', height: '15rem' }"
  ></p-confirmDialog>
</sersi-spin>
