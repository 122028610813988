import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AnimalHistoryDto } from '@ifhms/models/feedlot';

import { AH_FMS_FEEDLOT_API_CONFIG, AhFmsFeedlotApiConfig } from '../../tokens';

@Injectable()
export class FeedlotEventsAnimalHistoryService {

  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_FEEDLOT_API_CONFIG) private apiConfig: AhFmsFeedlotApiConfig
  ) {}

  getAnimalHistory(feedlotId: string, animalId: string, showPending = false): Observable<AnimalHistoryDto> {
    const params = new HttpParams().set('showPendingEvents', showPending);
    return this.http.get<AnimalHistoryDto>(`${this.apiConfig.apiUrl}/Feedlot/${feedlotId}/Animals/Details/${animalId}/History`, { params });
  }

}
