<ng-container
  *ngIf="{
    isLoading: sortGroupOutcomeCopyGridListFacade.isLoading$ | async,
    copyTo: sortGroupOutcomeCopyGridListFacade.copyTo$ | async,
    copyFrom: sortGroupOutcomeCopyGridListFacade.copyFrom$ | async,
    selectedLength: sortGroupOutcomeCopyGridListFacade.selectedLength$ | async
  } as obs"
>
  <p-dialog
    *transloco="let t; read: translateScope"
    [(visible)]="display"
    [modal]="display"
    [style]="{ width: '90vw', height: '90vh' }"
    [closable]="true"
    [header]="obs.copyTo ? t('copy-to') : t('copy-from')"
    (onHide)="onClose()"
    [draggable]="false"
    [closeOnEscape]="false"
    [maximizable]="true"
    appendTo="body"
  >
    <div class="flex flex-column gap-3">
      <sersi-spin [spin]="obs.isLoading">
        <div class="flex justify-content-between mb-2">
          <ifhms-sort-group-outcome-table-filter
            [facade]="sortGroupOutcomeCopyGridListFacade"
          ></ifhms-sort-group-outcome-table-filter>
          <ifhms-sort-group-outcome-table-sort
            [facade]="sortGroupOutcomeCopyGridListFacade"
            [singleRecord]="(sortGroupOutcomeCopyGridListFacade.list$ | async)?.[0]"
          ></ifhms-sort-group-outcome-table-sort>
        </div>
        <ifhms-sort-group-outcome-table
          [facade]="sortGroupOutcomeCopyGridListFacade"
          [disableSelectAll]="!!!obs.copyTo"
          (viewClick)="viewClick.emit($event)"
        >
        </ifhms-sort-group-outcome-table>
      </sersi-spin>
    </div>
    <ng-template pTemplate="footer">
      <div class="flex gap-1 justify-content-end mt-3" *ngIf="!obs.isLoading">
        <button
          pButton
          class="p-button-secondary w-15rem"
          [label]="t('cancel')"
          (click)="onClose()"
          [icon]="'pi pi-arrow-left'"
          [text]="true"
          [rounded]="true"
        ></button>

        <button
          pButton
          class="p-button-primary"
          [label]="
            t('apply', {
              selected: obs.copyTo ? obs.selectedLength : obs.copyFrom?.length
            })
          "
          [disabled]="!!!obs.selectedLength"
          (click)="onApply()"
          [icon]="'pi pi-plus-circle'"
          [rounded]="true"
        ></button>
      </div>
    </ng-template>
    <p-footer> </p-footer>
  </p-dialog>
</ng-container>
