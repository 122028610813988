<sersi-spin
  [spin]="sortGroupOutcomeListFacade.isLoading$ | async"
  *ngIf="{
    detail: sortGroupDetailFacade.detail$ | async,
    isLoading: sortGroupOutcomeListFacade.isLoading$ | async,
    completedLength: sortGroupOutcomeListFacade.completedLength$ | async,
    totalLength: sortGroupOutcomeListFacade.totalLength$ | async,
    selectedLength: sortGroupOutcomeListFacade.selectedLength$ | async,
    selected: sortGroupOutcomeListFacade.selected$ | async
  } as obs"
>
  <p-dialog
    *transloco="let t; read: translateScope"
    [(visible)]="display"
    [modal]="true"
    styleClass="sort-group-modal"
    [style]="{ width: '90vw', height: '90vh' }"
    [closable]="true"
    (onHide)="onCancel()"
    [closeOnEscape]="false"
    [draggable]="false"
    [maximizable]="true"
    appendTo="body"
  >
    <p-header class="flex flex-row justify-content-between w-full">
      <div>
        <p class="heading">
          <b>{{ t('summary') }}</b>
          {{ ' ' + obs.completedLength + ' of ' + obs.totalLength + ' Ready' }}
        </p>
        <p class="sub-heading">
          {{ t('name') + ': ' + obs.detail?.sortGroupName }}
        </p>
      </div>
      <div class="flex flex-row gap-4">
        <button
          [attr.data-ele]="'create-output-btn'"
          [label]="t('create')"
          class="p-button-primary p-button-outlined"
          icon="pi pi-plus-circle"
          (click)="createOutput.emit()"
          [disabled]="!!!obs.selectedLength"
          pButton
          type="submit"
        ></button>
        <ifhms-sort-group-outcome-table-menu
          class="h-full"
          *ngIf="obs.selectedLength"
          (createOutput)="createOutput.emit()"
          [selected]="obs.selected || []"
        ></ifhms-sort-group-outcome-table-menu>
      </div>
    </p-header>
    <div class="flex flex-column gap-3">
      <div class="flex justify-content-between mb-2">
        <ifhms-sort-group-outcome-table-filter
          [facade]="sortGroupOutcomeListFacade"
        ></ifhms-sort-group-outcome-table-filter>
        <ifhms-sort-group-outcome-table-sort
          [facade]="sortGroupOutcomeListFacade"
          [singleRecord]="(sortGroupOutcomeListFacade.list$ | async)?.[0]"
        ></ifhms-sort-group-outcome-table-sort>
      </div>
      <ifhms-sort-group-outcome-table
        (rowClick)="createOutput.emit($event)"
        (viewClick)="viewClick.emit($event)"
        [facade]="sortGroupOutcomeListFacade"
      >
      </ifhms-sort-group-outcome-table>
    </div>
    <ng-template pTemplate="footer">
      <div class="flex gap-1 justify-content-end mt-3">
        <button
          [attr.data-ele]="'back-to-work-order-btn'"
          pButton
          class="p-button-secondary w-15rem"
          [label]="t('back')"
          (click)="onCancel()"
          [icon]="'pi pi-arrow-left'"
          [rounded]="true"
          [text]="true"
        ></button>

        <button
          [attr.data-ele]="'complete-btn'"
          pButton
          class="p-button-primary"
          [label]="t('complete')"
          [disabled]="obs.completedLength !== obs.totalLength"
          (click)="onComplete()"
          [icon]="'pi pi-plus-circle'"
          [rounded]="true"
        ></button>
      </div>
    </ng-template>
    <p-footer> </p-footer>
  </p-dialog>
</sersi-spin>
