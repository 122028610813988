<sersi-spin [spin]="!!(isLoading$ | async)">
  <ng-container *transloco="let t; read: scopedNamespace">
    <div class="animal-availability-dialog">
      <p class="flex align-items-center gap-1">{{ animalAvailabilityDisplayModel?.message }}

        <a
          pButton
          icon="pi pi-external-link"
          iconPos="right"
          [label]="animalAvailabilityDisplayModel?.type || ''"
          class="p-button-link button-pristine button-link mt-0.5"
          href="{{ animalAvailabilityDisplayModel?.link }}"
          target="_blank"
          (click)="closeDialog()"
        ></a>
      </p>

      <div class="container-footer-buttons">
        <button
          pButton
          type="button"
          [rounded]="true"
          [label]="t('cancel-button-label')"
          (click)="closeDialog()"
        ></button>
      </div>
    </div>
  </ng-container>
</sersi-spin>
