<ng-container *transloco="let t; read: translateScope">
  <sersi-table-filter
    #tableFilter
    [btnLabel]="t('title')"
    [modalTitle]="t('title')"
    [btnClearLabel]="t('clear-all')"
    [btnApplyLabel]="t('apply')"
    [btnCloseLabel]="t('cancel')"
    [modalViewHeight]="'80vh'"
    [modalViewWidth]="'70vw'"
    (applyFilters)="applyFilter()"
  >
    <ifhms-sort-group-search-table-filter-form
      #filterForm
    ></ifhms-sort-group-search-table-filter-form>
  </sersi-table-filter>
</ng-container>
