import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';
import { SersiSelectListItem } from '@sersi/angular/formly/core';

@Component({
  selector: 'sersi-table-sort',
  templateUrl: './table-sort.component.html',
  styleUrls: ['./table-sort.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TableSortComponent {
  @HostBinding('class') class = 'sersi-table-sort-container';

  @Input() sortLabel: string;
  @Input() value: number | null;
  @Input() items: SersiSelectListItem[];
  @Input() disabled = false;
  @Input() placeholder = '';
  @Input() className = 'table-sort-control';
  @Output() sortChanged = new EventEmitter<SersiSelectListItem>();

}
