<p-dialog
  [header]="t('animal-selector')"
  [visible]="display"
  [modal]="display"
  [style]="{ width: '90vw', height: '90vh' }"
  [draggable]="false"
  [closable]="false"
  [maximizable]="true"
  *transloco="let t; read: translateScope"
  appendTo="body"
>

  <div class="flex flex-row gap-3 cattle-selector">
    <ifhms-cattle-selector-table
      class="flex-1"
      [attr.data-ele]="'available-animals-table'"
      [facade]="leftFacade"
      [animals]="leftFacade.list$ | async"
      [woAttributes]="woAttributes"
      [heading]="t('available-animals')"
      [allowUpload]="true"
      [showAnimalAvailability]="true"
    >
      <sersi-spin
        [spin]="!!(leftFacade.isLoading$ | async)"
        [hideContent]="true"
      >
        <p
          *ngIf="!!!(leftFacade.filterApplied$ | async); else elseBlock"
          class="text-center"
        >
          <b class="click" (click)="toggleSearch($event)">{{
              t('click-here')
            }}</b>
          {{ t('to-search') }}
        </p>
      </sersi-spin>
    </ifhms-cattle-selector-table>
    <div class="flex flex-column gap-3 justify-content-center">
      <p-button
        [attr.data-ele]="'move-to-right-btn'"
        icon="pi pi-chevron-right"
        (onClick)="moveToRight()"
        [disabled]="!!!(leftFacade.selectedLength$ | async)"
      ></p-button>
      <p-button
        [attr.data-ele]="'move-to-left-btn'"
        icon="pi pi-chevron-left"
        (onClick)="moveToLeft()"
        [disabled]="!!!(rightFacade.selectedLength$ | async)"
      ></p-button>
    </div>
    <ifhms-cattle-selector-table
      class="flex-1"
      [attr.data-ele]="'selected-animals-table'"
      [facade]="rightFacade"
      [animals]="rightFacade.list$ | async"
      [woAttributes]="woAttributes"
      [heading]="t('selected-animals')"
      [allowUpload]="false"
      [showAnimalAvailability]="false"
    >
      <sersi-spin
        [spin]="!!(rightFacade.isLoading$ | async)"
        [hideContent]="true"
      >
        <p
          *ngIf="!!!(rightFacade.filterApplied$ | async); else elseBlock"
          class="text-center"
        >
          {{ t('no-animals-selected') }}
        </p>
      </sersi-spin>
    </ifhms-cattle-selector-table>
  </div>
  <div class="flex gap-1 justify-content-end mt-3">
    <button
      pButton
      [attr.data-ele]="'cancel-btn'"
      class="p-button-secondary"
      [label]="t('cancel')"
      (click)="onCancel()"
      [rounded]="true"
      [text]="true"
    ></button>

    <button
      pButton
      [attr.data-ele]="'save-btn'"
      class="ml-3"
      [label]="t('save')"
      (click)="onSave()"
      [disabled]="!changes"
      [icon]="'pi pi-plus-circle'"
      [rounded]="true"
    >
    </button>
  </div>

  <ng-template #elseBlock
  ><p class="text-center">{{ t('no-result') }}</p></ng-template
  >
</p-dialog>
