<ng-container *transloco="let t; read: scopedNamespace">

    <form [formGroup]="form">
        <formly-form
                [fields]="fields"
                [form]="form"
                [model]="model"
        ></formly-form>
    </form>
    <div class="upc-gate-controller-button-container">
        <button
                pButton
                [disabled]="form.pristine"
                (click)="onUpdate()"
        >{{ t('save-button') }}
        </button>
        <button
                pButton
                (click)="onReset()"
        >{{ t('reset-button') }}
        </button>
    </div>

</ng-container>
