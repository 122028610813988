<ng-container *transloco="let t; read: translateScope">

  <div>

    <h3>
      {{ t('validation-hdr') }}
    </h3>


    @for (message of messages; track message) {
      <li>{{ message }}</li>
    } @empty {
      <li></li>
    }

  </div>

  <div class="flex flex-column row-gap-3 w-full">
    <h4>
      {{ t('continue') }}
    </h4>

    <div class="flex flex-row justify-content-between">
      <button
        pButton
        icon="pi pi-times"
        iconPos="left"
        [rounded]="true"
        severity="secondary"
        [label]="t('cancel')"
        (click)="onCancelClick()"
      ></button>

      <button
        pButton
        icon="pi pi-check"
        iconPos="left"
        [rounded]="true"
        [label]="t('ok')"
        (click)="onOkClick()"
      ></button>
    </div>

  </div>
</ng-container>
