import { AnimalAvailabilityDto } from '@ifhms/models/feedlot';
import { createReducer, on } from '@ngrx/store';

import { AnimalAvailabilityActions } from './animal-availability.actions';

export interface State {
  loading: boolean;
  loaded: boolean;
  animalAvailability: AnimalAvailabilityDto | null;
}

const initialState: State = {
  loading: true,
  loaded: false,
  animalAvailability: null
};

export const reducer = createReducer(
  initialState,

  on(AnimalAvailabilityActions.getAnimalAvailability, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),

  on(AnimalAvailabilityActions.getAnimalAvailabilitySuccess, (state, { animalAvailability }) => ({
    ...state,
    animalAvailability,
    loading: false,
    loaded: true
  })),

  on(AnimalAvailabilityActions.reset, () => initialState),

  on(AnimalAvailabilityActions.error, (state, action) => ({
    ...state,
    loading: false,
    loaded: false,
    errMsg: action.errMsg
  }))
);

export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;
export const getAnimalAvailability = (state: State): AnimalAvailabilityDto | null => state.animalAvailability;
