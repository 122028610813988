import { Product } from '@ifhms/models/feedlot';
import { HashMap } from '@jsverse/transloco';

// Get source list which is unique and not in predefined list
export function getUniqueProducts(source: Product[] | null, predefinedProducts: Product[] | null): Product[] {

  if (!source?.length) return [];
  if (!predefinedProducts?.length) return source;

  const predefinedProductsKeys = getUniqueProductsKeys(predefinedProducts);

  // Filter out any products that are already in predefinedProducts list
  return source.filter((product) => {
    const isDuplicateItemId = predefinedProductsKeys[product.id!];
    const isDuplicateProductId = predefinedProductsKeys[product.productId!];

    return !isDuplicateItemId && !isDuplicateProductId;
  });
}

export function getUserProductsWithAutoSuggestedProducts(existingProducts?: Product[], suggestedProducts?: Product[]): Product[] {
  const userProducts = getUserDefinedProducts(existingProducts || []);

  if (!userProducts.length) return suggestedProducts || [];
  if (!suggestedProducts?.length) return userProducts;

  const existingProductKeys = getUniqueProductsKeys(userProducts);
  // filter out any suggested products that are already in the user's list
  const uniqueSuggestedProducts = suggestedProducts.filter((sortGroupProduct) => {
    const isDuplicateEntry = existingProductKeys[sortGroupProduct.id!];
    const isDuplicateProduct = sortGroupProduct.productId && existingProductKeys[sortGroupProduct.productId!];
    return !isDuplicateEntry && !isDuplicateProduct;
  });
  return [...userProducts, ...uniqueSuggestedProducts];
}

export function getUserDefinedProducts(products: Product[]): Product[] {
  return products?.filter(product => !product.sortGroupProduct && !product.isTxProtocolProduct);
}

export function getUniqueProductsKeys(products: Product[]): HashMap<boolean> {
  return products.reduce((acc: HashMap<boolean>, product: Product) => {
    if (product.id) acc[product.id] = true;
    if (product.productId) acc[product.productId] = true;
    return acc;
  }, {} as HashMap<boolean>);
}
