import { Component, Inject, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { SHOW_DEV_FEATURE } from '@common/angular/config';
import { CommonDialogSize } from '@common/angular/interfaces';
import { scopeLoader } from '@common/angular/translation';
import { AhCommonDialogService } from '@common/angular/utils';
import { AnimalAvailabilityDialogComponent } from '@ifhms/feedlot/front-end/shared/components/animal-availability';
import { AnimalSelectorItemDto } from '@ifhms/models/feedlot';
import { HashMap, TRANSLOCO_SCOPE, TranslocoService } from '@jsverse/transloco';
import { MenuItem } from 'primeng/api';
import { combineLatest, Observable } from 'rxjs';

import { Facade, FEATURE_NAME } from '../../+state';
import { CattleSelectorWOAttributes } from '../../interfaces';
import { CattleSelectorTableSearchComponent } from '../cattle-selector-table-search';
import { CattleSelectorUploadFormComponent } from '../cattle-selector-upload-form';

@Component({
  selector: 'ifhms-cattle-selector-table',
  templateUrl: './cattle-selector-table.component.html',
  styleUrls: ['./cattle-selector-table.component.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: FEATURE_NAME,
        alias: FEATURE_NAME,
        loader: scopeLoader(
          (lang: string, root: string) => import(`../../${root}/${lang}.json`)
        )
      }
    }
  ]
})
export class CattleSelectorTableComponent implements OnInit {
  translateScope = `${FEATURE_NAME}.components.cattle-selector-table`;

  @Input() facade: Facade;

  @Input()
  set animals(animals: AnimalSelectorItemDto[] | null) {
    if (animals == null) return;
    this._animals = animals;
    this.selectedAnimals = animals.filter((animal) => animal.selected);
  }

  get animals(): AnimalSelectorItemDto[] {
    return this._animals;
  }

  actionMenuItems: MenuItem[];

  showDevFeature = false;

  status: Observable<boolean | null>;
  @Input() allowUpload: boolean;
  @Input() heading: string;
  @Input() woAttributes: CattleSelectorWOAttributes;
  @Input() showAnimalAvailability: boolean;

  @ViewChild(CattleSelectorTableSearchComponent, { static: false })
    search: CattleSelectorTableSearchComponent;

  private _animals: AnimalSelectorItemDto[] = [];
  private selectedAnimals: AnimalSelectorItemDto[] = [];
  private lastSelectedRowIndex = -1;
  private readonly DIALOG_HEIGHT = 'fit-content';

  constructor(
  @Inject(SHOW_DEV_FEATURE) showDevFeature: boolean,
    private dialogService: AhCommonDialogService,
    private translateService: TranslocoService,
    private renderer: Renderer2
  ) {
    this.showDevFeature = showDevFeature;
  }

  ngOnInit(): void {
    this.setActionsMenu();

    this.status = combineLatest(
      [this.facade.availableLength$, this.facade.selectedLength$],
      (total, selected) => {
        if (selected == 0) {
          return null;
        }
        else if (selected == total) return true;
        return false;
      }
    );
  }

  private setActionsMenu(): void {
    this.actionMenuItems = [
      {
        label: this.getTranslation('upload'),
        command: (): void => this.onUploadClick(),
        icon: 'pi pi-upload'
      }, {
        label: this.getTranslation('download'),
        command: (): void => this.onDownloadClick(),
        icon: 'pi pi-download'
      }
    ];
  }

  selectAll(): void {
    this.lastSelectedRowIndex = -1;
    this.facade.selectAll();
  }

  toggleAnimalSelection(
    animal: AnimalSelectorItemDto,
    rowIndex: number,
    event: MouseEvent
  ): void {
    const hasSelectedAnimals = this.lastSelectedRowIndex !== -1;
    const isSelectAction = !animal.selected;
    const isMultiSelectAction =
      event.shiftKey && hasSelectedAnimals && isSelectAction;

    if (isMultiSelectAction) {
      this.toggleMultiAnimalSelection(rowIndex);
      return;
    }

    this.toggleSingleAnimalSelection(animal, rowIndex, isSelectAction);
  }

  clearSearch(): void {
    this.lastSelectedRowIndex = -1;
    this.facade.clearFilter();
  }

  toggleSearch(event: Event): void {
    this.lastSelectedRowIndex = -1;
    this.search.toggleSearch(event);
  }

  private toggleMultiAnimalSelection(currentRowIndex: number): void {
    const rangeStart = Math.min(this.lastSelectedRowIndex, currentRowIndex);
    const rangeEnd = Math.max(this.lastSelectedRowIndex, currentRowIndex);
    const selectedAnimals = this.animals.slice(rangeStart, rangeEnd + 1).filter(x => x.isAvailable);
    this.lastSelectedRowIndex = currentRowIndex;
    this.facade.selectMultiple(selectedAnimals);
  }

  private toggleSingleAnimalSelection(
    animal: AnimalSelectorItemDto,
    rowIndex: number,
    isSelectAction: boolean
  ): void {
    let lastSelectedRowIndex = this.lastSelectedRowIndex;
    const isActionOnLastSelectedRow = this.lastSelectedRowIndex === rowIndex;

    // check if the last selected row is going to be deselected
    if (!isSelectAction && isActionOnLastSelectedRow) {
      // find max index of the selected animals
      const remainingSelectedAnimals = this.selectedAnimals.filter(
        (a) => a.id !== animal.id
      );
      const lastSelectedAnimal =
        remainingSelectedAnimals[remainingSelectedAnimals.length - 1];
      lastSelectedRowIndex = this.animals.findIndex(
        (a) => a.id === lastSelectedAnimal?.id
      );
    }
    this.lastSelectedRowIndex = isSelectAction
      ? rowIndex
      : lastSelectedRowIndex;
    this.facade.select(animal);
  }

  onUploadClick(): void {
    this.dialogService.openModal(
      CattleSelectorUploadFormComponent,
      {
        header: this.getTranslation('dialog-title'),
        height: this.DIALOG_HEIGHT,
        data: {
          woAttributes: this.woAttributes
        }
      },
      CommonDialogSize.MD
    );
  }

  onDownloadClick(): void {
    const link = this.renderer.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute(
      'href',
      'assets/downloads/cattle-selector-import-template.csv'
    );
    link.setAttribute('download', 'Sample_Import_File.csv');
    link.click();
    link.remove();
  }

  private getTranslation(key: string, params?: HashMap): string {
    return this.translateService.translate(
      `${this.translateScope}.${key}`,
      params
    );
  }

  showAnimalAvailabilityModal(animalId: string): void {
    this.dialogService.openModal(AnimalAvailabilityDialogComponent, {
      header: this.getTranslation('animal-availability-dialog-header'),
      data: { animalId }
    });
  }
}
