import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  AnimalSearchByIdDto,
  AnimalSearchByTagDto,
  AnimalSearchParamDto,
  EventsListDto,
  PostMortemEventAnimalDetailDto,
  PostMortemEventDto,
  PostMortemEventListDto,
  PostMortemEventListRequestDto,
  PostMortemEventUpdateDto
} from '@ifhms/models/feedlot';
import { Observable } from 'rxjs';
import { AH_FMS_FEEDLOT_API_CONFIG, AhFmsFeedlotApiConfig } from '../../tokens';
import { USE_DEFAULT_ERROR_HANDLER } from '@common/angular/error-handling';

@Injectable()
export class EventsPostMortemService {

  private context = new HttpContext().set(USE_DEFAULT_ERROR_HANDLER, false);

  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_FEEDLOT_API_CONFIG) private apiConfig: AhFmsFeedlotApiConfig
  ) {}

  batchComplete(feedlotId: string, eventIds: string[]): Observable<void> {
    return this.http.put<void>(`${this.apiConfig.apiUrl}/Feedlot/${feedlotId}/PostMortem/BatchComplete`, eventIds);
  }

  getList(feedlotId: string, request: PostMortemEventListRequestDto): Observable<PostMortemEventListDto> {
    const params = new HttpParams({ fromObject: request as unknown as Record<string, any> });
    return this.http.get<PostMortemEventListDto>(`${this.apiConfig.apiUrl}/Feedlot/${feedlotId}/PostMortem`, {
      params
    });
  }

  getEvent(feedlotId: string, eventId: string): Observable<PostMortemEventDto> {
    return this.http.get<PostMortemEventDto>(`${this.apiConfig.apiUrl}/Feedlot/${feedlotId}/PostMortem/${eventId}`);
  }
  
  getGotoEventList(feedlotId: string, eventId: string): Observable<EventsListDto> {
    return this.http.get<EventsListDto>(`${this.apiConfig.apiUrl}/Feedlot/${feedlotId}/PostMortem/${eventId}/GotoEventList`);
  }

  createEvent(feedlotId: string, eventUpdate: PostMortemEventUpdateDto): Observable<PostMortemEventDto> {
    return this.http.post<PostMortemEventDto>(
      `${this.apiConfig.apiUrl}/Feedlot/${feedlotId}/PostMortem/`,
      eventUpdate,
      { context: this.context }
    );
  }

  updateEvent(feedlotId: string, eventId: string, eventUpdate: PostMortemEventUpdateDto): Observable<PostMortemEventDto> {
    return this.http.put<PostMortemEventDto>(
      `${this.apiConfig.apiUrl}/Feedlot/${feedlotId}/PostMortem/${eventId}`,
      eventUpdate,
      { context: this.context }
    );
  }

  deleteEvent(feedlotId: string, eventId: string): Observable<string> {
    return this.http.delete<string>(`${this.apiConfig.apiUrl}/Feedlot/${feedlotId}/PostMortem/${eventId}`);
  }

  getNextEventId(feedlotId: string, eventId: string): Observable<string> {
    return this.http.get<string>(`${this.apiConfig.apiUrl}/Feedlot/${feedlotId}/PostMortem/${eventId}/Next`);
  }
  getPreviousEventId(feedlotId: string, eventId: string): Observable<string> {
    return this.http.get<string>(`${this.apiConfig.apiUrl}/Feedlot/${feedlotId}/PostMortem/${eventId}/Back`);
  }

  searchAnimal(feedlotId: string, searchParam: AnimalSearchParamDto): Observable<PostMortemEventAnimalDetailDto> {
    let params = new HttpParams();
    const { animalTag } = <AnimalSearchByTagDto>searchParam;
    const { nationalId } = <AnimalSearchByIdDto>searchParam;
    if(searchParam.eventDate)
      params = params.set('eventDate', searchParam.eventDate)
    if (animalTag) {
      params = params.set('tag', animalTag);
    }
    if (nationalId) {
      params = params.set('nationalId', nationalId);
    }

    return this.http.get<PostMortemEventAnimalDetailDto>(`${this.apiConfig.apiUrl}/Feedlot/${feedlotId}/PostMortem/AnimalUsages`, {
      params,
      context: this.context
    });
  }
}
