import { Component, forwardRef, HostBinding, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { scopeLoader } from '@common/angular/translation';
import { AnimalSelectorItemDto, AnimalSelectorItemDtoType } from '@ifhms/models/feedlot';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';

import { FEATURE_NAME } from '../../+state';
import { CattleSelectorLeftFacade } from '../../+state/left/left.facade';
import { CattleSelectorRightFacade } from '../../+state/right/right.facade';
import { CattleSelectorModalComponent } from '../../components';
import { CattleSelectorWOAttributes } from '../../interfaces';

@Component({
  selector: 'ifhms-cattle-selector-input',
  templateUrl: './cattle-selector-input.component.html',
  styleUrls: ['./cattle-selector-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CattleSelectorInputComponent),
      multi: true
    },
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: FEATURE_NAME,
        alias: FEATURE_NAME,
        loader: scopeLoader(
          (lang: string, root: string) => import(`../../${root}/${lang}.json`)
        )
      }
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class CattleSelectorInputComponent implements ControlValueAccessor {
  translateScope = `${FEATURE_NAME}.containers.cattle-selector-input`;

  _onChange: (_: any) => void;
  _onTouched: (_: any) => void;
  @Input() data: AnimalSelectorItemDto[] = [];
  @Input() disabled: boolean;
  @Input() woAttributes: CattleSelectorWOAttributes;
  @Input() bannerSeverity = 'warn';

  @ViewChild(CattleSelectorModalComponent)
    modal: CattleSelectorModalComponent;

  constructor(
    private rightFacade: CattleSelectorRightFacade,
    private leftFacade: CattleSelectorLeftFacade
  ) {
  }

  @HostBinding('class') class = 'cattle-selector';

  writeValue(animals: AnimalSelectorItemDto[]): void | null {
    if (animals == undefined) {
      return null;
    }
    if (animals.length > 0) {
      let trueType = true;
      animals.forEach((animal) => {
        if (animal?.type != AnimalSelectorItemDtoType) {
          trueType = false;
        }
      });
      if (trueType) {
        this.data = animals;
      }
    }
    this.rightFacade.clearFilter();
    this.leftFacade.clearFilter();
  }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setData = (animals: AnimalSelectorItemDto[]): void => {
    this.data = animals;
    this._onChange(animals);
  };

  showDialog = (): void => {
    this.modal.showDialog();
  };

  onEdit(): void {
    this.rightFacade.replace(this.data);
    this.showDialog();
  }

}
