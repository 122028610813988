import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AnimalSearchResultDto,
  AnimalSearchParamDto,
  AnimalSearchByTagDto,
  AnimalSearchByIdDto,
  AnimalUsagesDto
} from '@ifhms/models/feedlot';
import { AH_FMS_FEEDLOT_API_CONFIG, AhFmsFeedlotApiConfig } from '../../tokens';

@Injectable()
export class EventsAnimalSearchService {

  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_FEEDLOT_API_CONFIG) private apiConfig: AhFmsFeedlotApiConfig
  ) {}

  searchAnimal(feedlotId: string, searchParam: AnimalSearchParamDto): Observable<AnimalSearchResultDto> {
    let params = new HttpParams();
    const { animalTag } = <AnimalSearchByTagDto>searchParam;
    const { nationalId } = <AnimalSearchByIdDto>searchParam;

    if (animalTag) {
      params = params.set('tag', animalTag);
    }
    if (nationalId) {
      params = params.set('nationalId', nationalId);
    }

    return this.http.get<AnimalSearchResultDto>(`${this.apiConfig.apiUrl}/Feedlot/${feedlotId}/Events/AnimalSearch`, { params });
  }

  getUsages(feedlotId: string, animalId: string): Observable<AnimalUsagesDto> {
    return this.http.get<AnimalUsagesDto>(`${this.apiConfig.apiUrl}/Feedlot/${feedlotId}/Events/AnimalUsages/${animalId}`);
  }

}
