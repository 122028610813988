import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AhCommonDialogService, getUniqueValueChange, toPromise } from '@common/angular/utils';

import { EventAnimalProductMetaDataService } from '@ifhms/common/angular/data-access/feedlot-api';
import { AssignDosingGunFormComponent, EventMenuSetDoseFormComponent } from '@ifhms/common/angular/upc/dosing-guns';
import { DosingGunEventActionType, UpcDosingGunsFacade } from '@ifhms/common/angular/upc/shared';
import {
  ProductMetadataDialogComponent,
  ProductMetadataDialogParameters
} from '@ifhms/feedlot/front-end/shared/components/event-product-metadata';
import { FeedlotFacade } from '@ifhms/feedlot/front-end/shared/domain/state/feedlot';
import { ReferenceDataFacade } from '@ifhms/feedlot/front-end/shared/domain/state/reference-data';
import { UpcDosingGunsSettingsFormComponent } from '@ifhms/feedlot/front-end/shared/features/feedlot-settings/upc';
import { getFeedlotData } from '@ifhms/feedlot/shared/core/utils';
import { DeviceConnectionStatusEnum, DosingGunItem, Product, ProductMetadataDto } from '@ifhms/models/feedlot';
import { WorkOrderType } from '@ifhms/models/shared';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyTypesEnum, GridRendererCellType } from '@sersi/angular/formly/core';
import { isEqual } from 'lodash-es';
import {
  combineLatest,
  debounceTime,
  filter,
  firstValueFrom,
  Observable,
  of,
  startWith,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs';
import { getMetadataDialogProps, getProductMetadataValue } from '../../utils';
import { BaseProductItemComponent } from '../base-product-item.component';

@UntilDestroy()
@Component({
  selector: 'ifhms-event-product-item',
  templateUrl: './event-product-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventProductItemComponent extends BaseProductItemComponent {
  translateScope = 'product-item';

  protected get eventId(): string {
    return this.route.snapshot.params['eventId'];
  }

  protected override readonly fieldClassName = 'event-product-item';

  private dosingGunConnectionStatus: DeviceConnectionStatusEnum;

  private readonly defaultSelectFieldConfig = {
    type: FormlyTypesEnum.SINGLE_SELECT,
    className: 'text-base events-grid-dropdown input-sm',
    props: {
      optionsLabel: 'CODE_DESCRIPTION',
      selectedItemLabel: 'CODE',
      showClear: false,
      required: true,
      showErrInTooltip: true,
      virtualScroll: false,
      dropdownClassName: 'sersi-min-width-300',
      valueClassName: 'text-truncate',
      resetInvalidSelections: false
    },
    expressions: {
      'props.disabled': (): boolean => !!this.fieldConfig?.props?.disabled
    }
  };

  protected productMetaDataService = inject(EventAnimalProductMetaDataService);
  private router = inject(Router);

  constructor(
    protected override referenceDataFacade: ReferenceDataFacade,
    protected override dialogService: AhCommonDialogService,
    protected override feedlotFacade: FeedlotFacade,
    protected override route: ActivatedRoute,
    private dosingGunFacade: UpcDosingGunsFacade
  ) {
    super(
      referenceDataFacade,
      dialogService,
      feedlotFacade,
      route,
      dosingGunFacade
    );
  }

  protected override onFieldInit(field: FormlyFieldConfig): void {
    const dosingGunsEventsEnable = this.eventHasDosingGun();

    if (dosingGunsEventsEnable) {
      this.subscribeToDosingGunEvents();
      this.subscribeToDosingGunStatus();
    }
    super.onFieldInit(field);
    this.handleParentFormChanges();
    this.listenToSortGroupProductChanges();
    this.onProductGridChange(this.fieldConfig.model);
  }

  protected override onProductGridChange(updatedRowVal: Product): void {
    super.onProductGridChange?.(updatedRowVal);
    const dosingGunsEventsEnable = this.eventHasDosingGun();
    const productGunMacAddress = updatedRowVal.associatedDosingGunMacAddress;

    if (dosingGunsEventsEnable && productGunMacAddress) {
      this.sendWeightToDosingGunWithMacAddress(productGunMacAddress);
    }
  }

  protected getFieldGroupConfig(): FormlyFieldConfig[] {
    const fields = [
      ...this.setIds(),
      this.setProductTypes(),
      this.setProducts(),
      this.setRouteDetail(),
      this.setUserProductRecommendedQty(),
      this.setUserProductQty(),
      this.setUserProductUnit(),
      this.setAdminLocation(),
      this.setUserProductFlat(),
      this.setRowInfoButton()
    ];

    const dosingGunsEventsEnable = this.eventHasDosingGun();
    if (dosingGunsEventsEnable) {
      fields.push(this.setDosingGunButton());
    }

    fields.push(this.setRowDeleteButton());

    return fields;
  }

  protected setIds(): FormlyFieldConfig[] {
    return [
      super.setId(),
      {
        key: 'productLotNumber'
      },
      {
        key: 'productSerialNumber'
      },
      {
        key: 'expireDate'
      },
      {
        key: 'isCarryOver'
      },
      {
        key: 'associatedDosingGunMacAddress'
      }
    ];
  }

  protected override getAverageWeight(): number {
    return this.fieldConfig.form?.root.get('weight')?.value;
  }

  protected override async handleProductChange(
    updatedRowVal: Product
  ): Promise<Product> {
    if (updatedRowVal.productId !== this.formValue.productId) {
      updatedRowVal = await this.getProductWithLatestMetadata(updatedRowVal);
      this.updateProductMetadataModel(updatedRowVal);
    }

    return await super.handleProductChange(updatedRowVal);
  }

  private async updateProductMetadata(): Promise<void> {
    const updatedProduct = await this.getProductWithLatestMetadata(
      this.fieldConfig?.model
    );
    if (!isEqual(this.fieldConfig.model, updatedProduct)) {
      this.setMetadataValue(updatedProduct);
    }
  }

  private async getProductWithLatestMetadata(
    product: Product
  ): Promise<Product> {
    const updatedProduct = { ...product };
    const feedlotId = getFeedlotData(this.router)['id'];
    const { productId } = product;
    const facilityId = this.getFacilityId();
    if (feedlotId && facilityId && productId) {
      const metadata = await toPromise(
        this.productMetaDataService.getProductMetadata(
          feedlotId,
          facilityId,
          productId,
          this.eventId,
          this.fieldProps['eventType']
        )
      );
      return {
        ...updatedProduct,
        ...getProductMetadataValue(metadata, product)
      };
    }
    return updatedProduct;
  }

  // Formly fields configuration
  private setProductTypes(): FormlyFieldConfig {
    return {
      ...this.defaultSelectFieldConfig,
      key: 'productTypeId',
      props: {
        ...this.defaultSelectFieldConfig.props,
        items$: this.referenceDataFacade.productTypesWithProducts$,
        excludeItems$: this.excludedTypeIds,
        placeholder$: this.getParentTranslation$('type-label')
      }
    };
  }

  private setProducts(): FormlyFieldConfig {
    return {
      ...this.defaultSelectFieldConfig,
      key: 'productId',
      props: {
        ...this.defaultSelectFieldConfig.props,
        items$: this.getProductOptions(this.fieldConfig.model.productTypeId),
        excludeItems$: this.excludedProductIds,
        placeholder$: this.getParentTranslation$('product-label')
      }
    };
  }

  private setRouteDetail(): FormlyFieldConfig {
    const { productId } = this.fieldConfig.model;
    return {
      ...this.defaultSelectFieldConfig,
      key: 'routeDetailId',
      props: {
        ...this.defaultSelectFieldConfig.props,
        dropdownClassName: 'sersi-min-width-150',
        items$: this.getRouteOptions(productId),
        excludeItems$: this.excludedRouteIds,
        placeholder$: this.getParentTranslation$('route-label')
      }
    };
  }

  private setUserProductRecommendedQty(): FormlyFieldConfig {
    return {
      key: 'recQty',
      type: FormlyTypesEnum.TEXT_READONLY,
      props: {
        className: 'text-sm font-medium'
      }
    };
  }

  private setUserProductQty(): FormlyFieldConfig {
    return {
      key: 'qty',
      type: FormlyTypesEnum.NUMBER_INPUT,
      className: 'text-base',
      props: {
        disabled: false,
        maxFractionDigits: 1,
        min: 0.1,
        showErrInTooltip: true,
        required: true
      }
    };
  }

  private setUserProductUnit(): FormlyFieldConfig {
    return {
      key: 'unit',
      type: FormlyTypesEnum.TEXT_READONLY,
      props: {
        className: 'text-sm font-medium'
      }
    };
  }

  private setAdminLocation(): FormlyFieldConfig {
    return {
      ...this.defaultSelectFieldConfig,
      key: 'adminLocationId',
      props: {
        ...this.defaultSelectFieldConfig.props,
        items$: this.referenceDataFacade.injectionLocations$,
        dropdownClassName: 'sersi-min-width-150',
        placeholder$: this.getParentTranslation$('loc-label')
      },
      expressions: {
        'props.required': (): boolean =>
          !this.fieldConfig?.props?.['isLocationOptional']
      }
    };
  }

  private setUserProductFlat(): FormlyFieldConfig {
    return {
      key: 'flat',
      type: FormlyTypesEnum.CHECKBOX,
      props: {
        readonly: true,
        hideLabel: true
      }
    };
  }

  private setRowInfoButton(): FormlyFieldConfig {
    return {
      props: {
        fieldKey: 'info-btn',
        onClick: () => this.handleInfoAction(),
        cellRendererParams: {
          type: GridRendererCellType.Info
        }
      },
      expressions: {
        'props.actionDisabled': () => !this.fieldConfig?.model?.productId
      }
    };
  }

  private setRowDeleteButton(): FormlyFieldConfig {
    return {
      props: {
        fieldKey: 'add-delete-btn',
        cellRendererParams: {
          type: GridRendererCellType.Delete
        }
      }
    };
  }

  private setDosingGunButton(): FormlyFieldConfig {
    return {
      props: {
        fieldKey: 'dosing-gun-btn',
        translateFn: (key: string) => this.getParentTranslation(key),
        onClick: () => this.handleDosingGunAction(),
        onAction: this.handleDosingGunMenuAction.bind(this),
        eventButton: true,
        cellRendererParams: {
          type: GridRendererCellType.DosingGun
        }
      }
    };
  }

  // Field helpers & side effect handlers
  private handleParentFormChanges(): void {
    this.getSelectedProducts()
      .pipe(takeUntil(this.fieldDestroy$))
      .subscribe((products: Product[]) => this.setExcludedItems(products));

    this.getWeightChange()
      .pipe(
        filter(() => !this.skipChangeEvent),
        filter((weight) => this.getFormState()['weight'] !== weight),
        takeUntil(this.fieldDestroy$),
        debounceTime(280)
      )
      .subscribe(async (weight) => {
        const gun = await this.getActiveDosingGun();
        await this.setProductRecommendedQty(weight, true, !!gun);
        this.sendWeightToDosingGun(gun);
      });

    this.getFacilityChange()
      .pipe(
        filter(() => !this.skipChangeEvent),
        filter((facilityId: string) => {
          const hasProductId = !!this.fieldConfig.model?.productId;
          const isUserChange = this.getFormState()['facilityId'] !== facilityId;
          return hasProductId && isUserChange;
        }),
        takeUntil(this.fieldDestroy$)
      )
      .subscribe(() => this.updateProductMetadata());
  }

  private async getActiveDosingGun(): Promise<DosingGunItem | null> {
    const isDosingGunConnected =
      this.dosingGunConnectionStatus === DeviceConnectionStatusEnum.Connected;
    const gun = await this.getAssignedDosingGun();
    const isGunActive =
      isDosingGunConnected &&
      gun?.productId === this.fieldConfig.model.productId &&
      !!gun?.validated;
    return isGunActive ? gun : null;
  }

  private getSelectedProducts(): Observable<Product[]> {
    const formControl = this.getTableFormControl();
    const products$ = formControl?.valueChanges.pipe(
      startWith(formControl.value || []),
      filter((products) => !isEqual(products, this.selectedProducts)),
      tap((selectedProducts) => (this.selectedProducts = selectedProducts))
    );
    return products$ || of([]);
  }

  private getWeightChange(): Observable<number> {
    const formControl = this.fieldConfig?.form?.root.get('weight');
    // ignore weight change for flat products
    const targetCtrl = this.fieldConfig.model.flat ? null : formControl;
    return getUniqueValueChange<number>(targetCtrl);
  }

  private getFacilityChange(): Observable<string> {
    const formControl = this.getFacilityFormControl();
    return getUniqueValueChange<string>(formControl);
  }

  private getFacilityFormControl(): AbstractControl<string> | null {
    return this.fieldConfig?.form?.root.get('facilityId') || null;
  }

  private getFacilityId(): string | null {
    const facilityCtrl = this.getFacilityFormControl();
    return facilityCtrl?.value || null;
  }

  private getWorkOrderId(): string {
    return this.route.snapshot.params['workOrderId'];
  }

  private handleInfoAction(): void {
    const model = this.fieldConfig?.model;
    const productId = model.productId;
    const facilityId = this.getFacilityId();

    this.dialogService
      .openModal(ProductMetadataDialogComponent, {
        header: this.getParentTranslation('dialog-title'),
        data: {
          product: {
            facilityId,
            productId,
            productTypeId: model.productTypeId
          },
          metadata: getMetadataDialogProps(this.fieldConfig?.model),
          disabled: this.fieldConfig?.props?.disabled,
          eventId: this.eventId,
          eventType: this.fieldProps['eventType']
        } as ProductMetadataDialogParameters
      })
      .onClose.pipe(
        filter((val) => !!val),
        untilDestroyed(this)
      )
      .subscribe((value) => this.setMetadataValue(value));
  }

  private setMetadataValue(metadata: ProductMetadataDto | Product): void {
    this.updateProductMetadataModel(metadata);
    this.fieldConfig.form?.patchValue(this.fieldConfig.model);
    this.fieldConfig.form?.markAsTouched();
  }

  private updateProductMetadataModel(metadata: ProductMetadataDto | Product): void {
    this.fieldConfig.model.productLotNumber = metadata.productLotNumber;
    this.fieldConfig.model.productSerialNumber = metadata.productSerialNumber;
    this.fieldConfig.model.expireDate = metadata.expireDate;
    this.fieldConfig.model.isCarryOver = metadata.isCarryOver;
    this.fieldConfig.model.adminLocationId =
      metadata.adminLocationId || this.fieldConfig.model.adminLocationId;
  }

  private async getAssignedDosingGun(macAddress?: string): Promise<DosingGunItem | null> {
    const { productId } = this.fieldConfig.model;
    if (!productId) return null;

    const dosingGuns = await firstValueFrom(this.dosingGunsFacade.dosingGunItems$);
    if (!dosingGuns || dosingGuns.length == 0) return null;

    const workOrderNumber = this.fieldConfig?.form?.root.value.workOrderNumber;
    let gun;
    if (macAddress) {
      gun = dosingGuns.find(
        (dg: DosingGunItem) => dg.productId === productId
          && dg.workOrderNumber === workOrderNumber
          && (dg.macAddress === macAddress)
      );
    }
    else {
      gun = dosingGuns.find(
        (dg: DosingGunItem) => dg.productId === productId
          && dg.workOrderNumber === workOrderNumber
      );
    }

    if (!gun) return null;

    return gun;
  }

  // Synced with current Dosing Gun status
  // inform recently connected gun about current recommended qty
  private subscribeToDosingGunStatus(): void {
    combineLatest([
      this.dosingGunFacade.dosingGunsConnectionStatus$,
      this.dosingGunFacade.dosingGunItems$
    ])
      .pipe(takeUntil(this.fieldDestroy$))
      .subscribe(async ([status, guns]) => {
        this.dosingGunConnectionStatus = status;
        if (status !== DeviceConnectionStatusEnum.Connected || guns?.length) {
          return;
        }
        const gunMacAddress = await this.getActiveDosingGun();
        this.sendWeightToDosingGun(gunMacAddress);
      });
  }

  // Listen to Trigger Pull from dosing gun and sync product item qty field with actual dose from the gun
  // reset one time dose in state if it was set
  private subscribeToDosingGunEvents(): void {
    this.dosingGunFacade.dosingGunEvents$
      .pipe(
        withLatestFrom(this.dosingGunFacade.oneTimeDose$),
        takeUntil(this.fieldDestroy$)
      )
      .subscribe(async ([data, oneTimeDose]) => {
        const gun = await this.getAssignedDosingGun(data.gunId);

        if (!gun?.validated) return;

        if (oneTimeDose) {
          this.dosingGunFacade.resetOneTimeDose();
        }

        if (gun.productId !== this.fieldConfig.model?.productId) return;

        this.fieldConfig.formControl?.patchValue({ qty: data.quantity });
      });
  }

  private async handleDosingGunMenuAction(
    action: DosingGunEventActionType
  ): Promise<void> {
    const gun = await this.getAssignedDosingGun();
    switch (action) {
      case DosingGunEventActionType.SetOneTimeDose:
        this.dialogService.openModal(EventMenuSetDoseFormComponent, {
          header: this.getParentTranslation('set-dose-form-header'),
          data: {
            gun: gun
          }
        });
        break;
      case DosingGunEventActionType.UnassignDosingGun:
        this.dosingGunsFacade.UnassignDosingGunFromProduct(gun!.id);
        this.cdr.markForCheck();
        break;
      case DosingGunEventActionType.OpenSettings:
        this.dialogService.openModal(UpcDosingGunsSettingsFormComponent, {
          header: this.getParentTranslation('dosing-gun-settings-form-header'),
          data: {
            gun: gun
          },
          width: '90rem'
        });
        break;
    }
  }

  private handleDosingGunAction(): void {
    if (
      this.dosingGunConnectionStatus === DeviceConnectionStatusEnum.Connected
    ) {
      const config = {
        ...this.fieldConfig?.form?.value,
        workOrderId: this.getWorkOrderId(),
        workOrderNumber: this.fieldConfig?.form?.root.value.workOrderNumber,
        workOrderType: this.fieldConfig?.form?.root.value.workOrderType
      };
      this.dialogService.openModal(AssignDosingGunFormComponent, {
        data: config,
        header: this.fieldProps['translateFn']('assign-dosing-gun-header')
      });
    }

    return;
  }

  private sendWeightToDosingGun(gun: DosingGunItem | null): void {
    // send the dose to gun if connected
    const hasWeight = this.getAverageWeight();
    if (gun && gun.macAddress && hasWeight) {
      this.dosingGunFacade.setOneTimeDose(gun.macAddress, this.fieldConfig.model.recQty, gun.name);
    }
  }

  private sendWeightToDosingGunWithMacAddress(macAddress: string | null): void {
    // send the dose to gun if connected
    const hasWeight = this.getAverageWeight();
    if (macAddress && hasWeight) {
      this.dosingGunFacade.setOneTimeDose(macAddress, this.fieldConfig.model.recQty);
    }
  }

  private eventHasDosingGun(): boolean {
    return (
      this.fieldConfig?.form?.root.value.workOrderType ===
      WorkOrderType.Arrival ||
      this.fieldConfig?.form?.root.value.workOrderType ===
      WorkOrderType.ReHandling
    );
  }

  private listenToSortGroupProductChanges(): void {
    this.fieldConfig.formControl?.valueChanges
      .pipe(
        filter(value => !!value && value.productId !== this.formValue.productId),
        untilDestroyed(this)
      )
      .subscribe((value) => {
        this.onProductGridChange(value);
      });
  }
}