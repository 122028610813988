import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable, tap } from 'rxjs';

import { AH_FMS_FEEDLOT_API_CONFIG, AhFmsFeedlotApiConfig } from '@ifhms/common/angular/data-access/feedlot-api';
import { DsortAuthDto, DSortCredentialDto, DSortDto, DSortPostDto, DSortPutDto } from '@ifhms/models/feedlot';

import { AH_FMS_DSORT_CONFIG, AhFmsDSortConfig } from '../tokens';

@Injectable()
export class DsortApiService {
  
  private readonly apiUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_DSORT_CONFIG) private dSortConfig: AhFmsDSortConfig,
    @Inject(AH_FMS_FEEDLOT_API_CONFIG) private apiConfig: AhFmsFeedlotApiConfig
  ) {
    this.apiUrl = this.dSortConfig.dSortUrl
  }
  
  getDsortCredential(feedlotId: string): Observable<DSortCredentialDto> {
    return this.http.get<DSortCredentialDto>(
      `${this.apiConfig.apiUrl}/Feedlot/${feedlotId}/Dsort/Credential`
    );
  }

  authenticate(username: string, password: string): Observable<DsortAuthDto> {
    const authBody = `grant_type=password&username=${username}&password=${password}`;
    return this.http.post<DsortAuthDto>(`${this.apiUrl}/token`, authBody).pipe(
      tap((response: DsortAuthDto) => {
        localStorage.setItem('dSortAuthToken', response.access_token);
      })
    );
  }

  sortAnimal(data: DSortPostDto): Observable<DSortDto> {
    const token = localStorage.getItem('dSortAuthToken');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<DSortDto>(`${this.apiUrl}/api/sort`, data, { headers });
  }

  updateAnimal(data: DSortPutDto): Observable<DSortDto> {
    const token = localStorage.getItem('dSortAuthToken');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.put<DSortDto>(`${this.apiUrl}/api/sort`, data, { headers });
  }

}
